<template>
  <div>
    <v-card flat elevation="0">
      <v-toolbar dense elevation="0">
        <div style="max-width: 100px">
          <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
        </div>

        <v-spacer></v-spacer>

        <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
        <v-btn icon @click="dialogFilter = true" title="Filter">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="px-2" v-if="isMobile">
        <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
      </div>

      <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
        <template v-slot:[`item.id`]="{ item }" v-if="!isMobile">
          <div class="d-block">
            <app-tooltip text="Edit">
              <v-btn small color="primary" @click="redirect('HumanResource.Employee.Edit', { id: item.id })" icon><v-icon>mdi-pencil</v-icon></v-btn>
            </app-tooltip>
          </div>
        </template>
        <template v-slot:[`item.emp_id`]="{ item }" v-if="!isMobile">
          <a href="javascript:;" @click="showDetail(item)">{{ item.emp_id }}</a>
        </template>
        <template v-slot:[`item.status`]="{ item }" v-if="!isMobile">
          <span v-if="item.status == true" class="mx-auto">
            <app-tooltip text="Active">
              <v-icon dense color="success">mdi-checkbox-marked-circle-outline</v-icon>
            </app-tooltip>
          </span>
          <span v-else class="mx-auto">
            <app-tooltip text="In Active">
              <v-icon dense color="error">mdi-block-helper</v-icon>
            </app-tooltip>
          </span>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

        <template v-slot:[`item`]="{ item }" v-if="isMobile">
          <v-card elevation="4" class="ma-1" outlined>
            <v-card-title class="text-h5 mb-1 pb-1"><a href="javascript:;" @click="showDetail(item)">{{ item.emp_id }}</a></v-card-title>
            <v-card-subtitle class="my-0 py-0">
              {{ item.full_name }}<br>
              {{ item.pos_name_eng }}
            </v-card-subtitle>
            <v-card-actions class="d-flex align-center flex-row mx-2">
              <v-btn @click="redirect('HumanResource.Employee.Edit', { id: item.id })" outlined rounded color="primary">Edit</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="redirect('HumanResource.Employee.Detail', { id: item.id })" outlined rounded color="primary">Detail</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-table>
      <div class="text-right pt-2 mx-auto" style="max-width: 600px">
        <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
      </div>
    </v-card>


    <v-dialog v-model="dialogFilter" max-width="600">
      <v-card>
        <v-card-title>Filter</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-3">
          <v-select label="Status" v-model="filterStatus" return-object :items="filterData.status" item-text="text" item-value="value"></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" :loading="datatable.loading" @click="refreshData(), dialogFilter = !dialogFilter">Filter</v-btn>
          <v-btn color="warning" :loading="datatable.loading" @click="resetFilter(), dialogFilter = !dialogFilter">Reset</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialogFilter = !dialogFilter">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer v-model="drawer" fixed bottom temporary right width="360" class="pt-12">
      <v-toolbar color="primary" dark elevation="0" dense absolute width="100%">
        <v-toolbar-title>{{ selectedEmployee.full_name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon text small @click.stop="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <detail :employee="selectedEmployee"></detail>
    </v-navigation-drawer>
  </div>
</template>

<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
  font-size: 0.875rem !important;
  height: 28px !important;
}
</style>

<script>
import Detail from './Detail.vue';
export default {
  components: { Detail },
  data() {
    return {
      drawer: false,
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "Options", value: "id", class: "text-no-wrap" },
          { text: "Emp ID", value: "emp_id", class: "text-no-wrap" },
          { text: "Full Name", value: "full_name", class: "text-no-wrap" },
          { text: "Emp No.", value: "emp_no_", class: "text-no-wrap" },
          // { text: "Email", value: "email", class: "text-no-wrap" },
          { text: "Pos Name (en)", value: "pos_name_eng", class: "text-no-wrap" },
          { text: "Work Loc.", value: "work_location_code", class: "text-no-wrap" },
          { text: "Spv Parent", value: "spv_parent", class: "text-no-wrap" },
          { text: "Spv Name", value: "spv_name", class: "text-no-wrap" },
          // { text: "Spv Path", value: "spv_path", class: "text-no-wrap" },
          { text: "Status", value: "status", class: "text-no-wrap text-center" },
        ],
        data: [],
      },
      dialogFilter: false,
      filterData: {
        status: [
          {
            text: 'All',
            value: null,
          },
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'In Active',
            value: false,
          }
        ]
      },
      filterLoading: false,
      filterStatus: {},
      selectedEmployee: {},
    };
  },

  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    refreshData() {
      this.getData(true);
    },
    resetFilter() {
      this.filterStatus = {}
      this.getData(true);
    },
    getData(refresh = false) {
      if (refresh) this.AxiosStorageRemove("POST", "employee/data");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      if (typeof this.filterStatus.value != 'undefined' && this.filterStatus.value != null){
        formData.append("status", this.filterStatus.value);
      }

      this.$axios
        .post("employee/data", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    showDetail(item) {
      this.selectedEmployee = item;
      this.drawer = true;
    }
  },

  mounted() {
    this.getData(true);
  },
};
</script>