<template>
  <div>
    <v-card elevation="0" flat>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Employee ID</v-list-item-title>
          <v-list-item-subtitle>{{ employee.emp_id }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>First Name</v-list-item-title>
          <v-list-item-subtitle>{{ employee.first_name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Middle Name</v-list-item-title>
          <v-list-item-subtitle>{{ employee.middle_name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Last Name</v-list-item-title>
          <v-list-item-subtitle>{{ employee.last_name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Email</v-list-item-title>
          <v-list-item-subtitle>{{ employee.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Position</v-list-item-title>
          <v-list-item-subtitle>{{ employee.pos_name_eng }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Work Location</v-list-item-title>
          <v-list-item-subtitle>{{ employee.work_location_code }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Spv Parent</v-list-item-title>
          <v-list-item-subtitle>{{ employee.spv_parent }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Spv Name</v-list-item-title>
          <v-list-item-subtitle>{{ employee.spv_name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Status</v-list-item-title>
          <v-list-item-subtitle>{{ (employee.status) ? 'Active' : 'In Active' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    employee: {}
  },
  
}
</script>