<template>
  <div>
    <v-container>
      <page-title title="Employee">
        <template slot="action">
          <v-btn color="primary" :to="{ name: 'HumanResource.Employee.Add' }">Add</v-btn>
        </template>
      </page-title>

      <v-card class="mb-2">
        <list-data></list-data>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ListData from './components/ListData.vue';

export default {
  components: {
    ListData,
  }
}
</script>